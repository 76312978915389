import React, { useState } from "react"
import { graphql } from "gatsby"

import Seo from "../components/SEO"
import { LayoutContainer, Main, BlogCard, SearhBar } from "../components/UI"
import Layout from "../components/Layout"
import SearchHits from "../components/SearchHits/SearchHits"
import CategoriesBox from "../components/CategoriesBox/CategoriesBox"
import SectionTitle from "../components/SectionTitle"

const CategoryPostTemplate = ({
  data: { allDatoCmsBlog },
  pageContext: { name },
  location,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const inputHandler = e => {
    setSearchTerm(e.target.value)
  }
  const canonicalUrl = `https://thetoucan.app${location.pathname}`
  return (
    <Layout isBlogPage>
      <Seo
        title={`Category - ${name}`}
        description="Blogs by Toucan"
        canonicalUrl={canonicalUrl}
      />
      <Main className="bg-white pb-14 md:pb-16 lg:pb-32">
        <div className="mb-[28px] rounded-b-[50px] bg-header-cta px-4 pt-[137px] pb-[42px] md:pt-[200px] md:pb-[100px]">
          <div className="mx-auto max-w-[900px]">
            <h1 className="custom-text-shadow mb-8 text-center font-spartan text-4xl font-bold leading-[52px] text-white md:mb-16 md:text-6xl">
              {name}
            </h1>
            <div className="relative">
              <SearhBar onChange={inputHandler} />
              <SearchHits
                searchTerm={searchTerm}
                posts={allDatoCmsBlog.nodes}
              />
            </div>
          </div>
        </div>
        <LayoutContainer>
          <CategoriesBox className="mb-14 md:mb-16" />
          <div className="mb-12">
            <SectionTitle>Featured Articles</SectionTitle>
          </div>
          <div className="grid grid-cols-1 justify-between gap-5 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-12">
            {React.Children.toArray(
              allDatoCmsBlog.nodes.map(p => (
                <BlogCard
                  title={p.title}
                  slug={p.slug}
                  image={p.heroImage}
                  author={p.author}
                  category={p.category}
                  excerpt={p.excerptFromBody}
                />
              ))
            )}
          </div>
        </LayoutContainer>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query GetCategoriesPost($slug: String!) {
    allDatoCmsBlog(
      filter: { category: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        title
        slug
        excerptFromBody
        heroImage {
          gatsbyImageData(imgixParams: { maxW: 100, maxH: 100 })
          alt
        }
        author {
          name
          slug
        }
        category {
          name
          slug
        }
      }
    }
  }
`

export default CategoryPostTemplate
